import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { countries } from "../../utils/countryList";
import IBAN from "iban";

const PaymentInformation = ({ data, setData, personalInfo, errors }) => {
  const [ibanError, setIbanError] = useState("");


  useEffect(()=>{
    if(data.is_account_holder_same_name ==="y"){
      setData({
        ...data,
        account_holder_fname: personalInfo.fname,
        account_holder_lname: personalInfo.lname,
      });
    }
    else{
      setData({
        ...data,
        account_holder_fname: "",
        account_holder_lname: "",
      });
    }
  },[data.is_account_holder_same_name])

  const handleCountryChange = (event, value) => {
    if (value && value.code !== "CH") {
      setData({
        ...data,
        country_of_bank: value.code,
        swift: "",
        bank_clearing: "",
        account_number: "",
      });
    } else {
      setData({
        ...data,
        country_of_bank: value ? value.code : "",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);

    if (name === "iban") {
      const isValidIban = IBAN.isValid(value);
      setIbanError(isValidIban ? "" : "Invalid IBAN number");
    }
    
      setData({
        ...data,
        [name]: value,
       
      });
    
  };

  return (
    <Container
      sx={{
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        border: "1px solid #e0e0e0",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontSize: "1.2rem", marginBottom: "20px" }}
      >
        Participate in experiences with compensation by bank transfer!
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "20px" }}>
        Please carefully fill in the information below to ensure the smooth
        processing of bank transfers.
      </Typography>
      <FormControl component="fieldset" sx={{ marginBottom: "20px" }}>
        <FormLabel component="legend">Are you the account holder?</FormLabel>
        <RadioGroup
          row
          aria-label="is_account_holder_same_name"
          name="is_account_holder_same_name"
          value={data.is_account_holder_same_name ?? ""}
          onChange={handleInputChange}
        >
          <FormControlLabel value="y" control={<Radio />} label="Yes" />
          <FormControlLabel value="n" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      {data.is_account_holder_same_name === "n" && (
        <Box sx={{ marginBottom: 5 }}>
          <Typography variant="body1" gutterBottom color="error">
            Please download the PDF document below to learn about the procedure
            to follow if you are not the account holder.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/files/procédure_procuration.pdf" 
            download
            sx={{ textTransform: "none" }}
          >
            Download the document
          </Button>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First name of account holder"
            variant="outlined"
            fullWidth
            name="account_holder_fname"
            disabled={data.is_account_holder_same_name === "y"}
            value={
              data.is_account_holder_same_name === "y"
                ? personalInfo.fname
                : data.account_holder_fname
            }
            onChange={handleInputChange}
            sx={{ marginBottom: "20px" }}
            error={Boolean(errors.account_holder_fname)}
            helperText={errors.account_holder_fname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Surname of account holder"
            disabled={data.is_account_holder_same_name === "y"}
            variant="outlined"
            fullWidth
            name="account_holder_lname"
            value={
              data.is_account_holder_same_name === "y"
                ? personalInfo.lname
                : data.account_holder_lname
            }
            onChange={handleInputChange}
            sx={{ marginBottom: "20px" }}
            error={Boolean(errors.account_holder_lname)}
            helperText={errors.account_holder_lname}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="country-select"
            options={countries}
            getOptionLabel={(option) => option.code}
            value={
              countries.find((c) => c.code === data.country_of_bank) || null
            }
            onChange={handleCountryChange}
            filterOptions={(options, state) =>
              options.filter(
                (option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()) ||
                  option.code
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
              )
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a bank country"
                margin="normal"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                }}
                error={Boolean(errors.country_of_bank)}
                helperText={errors.country_of_bank}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="IBAN number"
            variant="outlined"
            fullWidth
            name="iban"
            value={data.iban}
            onChange={handleInputChange}
            error={Boolean(ibanError || errors.iban)}
            helperText={ibanError || errors.iban}
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
      </Grid>
      {data.country_of_bank !== "CH" && (
        <>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            Please fill in the following fields if you do not have an account in
            Switzerland
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Account number"
                variant="outlined"
                fullWidth
                name="account_number"
                value={data.account_number}
                onChange={handleInputChange}
                sx={{ marginBottom: "20px" }}
                error={Boolean(errors.account_number)}
                helperText={errors.account_number}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="BIC (SWIFT) code"
                variant="outlined"
                fullWidth
                name="swift"
                value={data.swift}
                onChange={handleInputChange}
                sx={{ marginBottom: "20px" }}
                error={Boolean(errors.swift)}
                helperText={errors.swift}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Bank clearing number"
                variant="outlined"
                fullWidth
                name="bank_clearing"
                value={data.bank_clearing}
                onChange={handleInputChange}
                sx={{ marginBottom: "20px" }}
                error={Boolean(errors.bank_clearing)}
                helperText={errors.bank_clearing}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default PaymentInformation;
