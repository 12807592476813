const getStatusPayment = (status) => {
  console.log(status);

  switch (status) {
    case 0:
      return "Confirmation needed";
    case 1:
      return "Confirmed";
    default:
  }
};

const getStatusExperience = (status) => {
  switch (status) {
    case 0:
    case 1:
      return "Finished";
    default:
  }
};

export { getStatusPayment, getStatusExperience };
