import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";

const Policies = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" paragraph>
          The University of Lausanne (UNIL) places great importance on
          respecting the privacy of individuals whose personal data is processed
          at any time in the context of their relationship with the University.
        </Typography>

        <Typography variant="body1" paragraph>
          Article 15 of the Constitution of the Canton of Vaud (Cst-VD; BLV
          101.1) and the cantonal law of September 11, 2007, on the protection
          of personal data (LPrD; BLV 172.65) state that everyone has the right
          to the protection of their privacy and to be protected against the
          misuse of data concerning them.
        </Typography>

        <Typography variant="body1" paragraph>
          As the data controller during the use of the ORSEE recruitment
          platform or when participating in one of the behavioral experiments of
          the HEC Behavioral Experimental Laboratory (hereinafter: HEC-LABEX),
          HEC-LABEX, attached to the Faculty of Business and Economics (HEC),
          respects these prerogatives.
        </Typography>

        <Typography variant="body1" paragraph>
          This policy concerns the processing of your personal data collected as
          a user of the ORSEE recruitment platform of HEC-LABEX or as a
          participant in one of the behavioral experiments of HEC-LABEX.
        </Typography>

        <Typography variant="body1" paragraph>
          This privacy policy is dated June 5, 2020. By creating a participant
          profile on the HEC-LABEX ORSEE recruitment platform or by
          participating in one of our behavioral experiments, you acknowledge
          that you have read this privacy policy and accept that we process your
          personal data as described in this privacy policy.
        </Typography>

        <Typography variant="body1" paragraph>
          This privacy policy is subject to regular updates, in which case you
          will be informed via the website{" "}
          <a
            href="https://labex.unil.ch/orsee"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://labex.unil.ch/orsee
          </a>
          . Any use of the ORSEE recruitment platform of HEC-LABEX following
          this notification will be considered acceptance of the latest version
          of this policy.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h5" component="h2" gutterBottom>
          1. What is meant by personal data?
        </Typography>

        <Typography variant="body1" paragraph>
          Personal data refers to any information that relates to an identified
          or identifiable individual. Personal data may either directly identify
          you (e.g., your name and surname) or indirectly identify you by
          cross-referencing various pieces of information about you (e.g., your
          phone number).
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. What personal data is processed by HEC-LABEX?
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>
            A. Connection to the participant profile via SWITCH edu-ID
          </strong>
          <br />
          Online access to your participant profile on the HEC-LABEX ORSEE
          recruitment platform requires the creation of a SWITCH edu-ID account
          (a two-factor authentication system using your email address and
          mobile number). The terms of use for SWITCH edu-ID are available at:{" "}
          <a
            href="https://eduid.ch/web/tou/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://eduid.ch/web/tou/
          </a>
        </Typography>

        <Typography variant="body1" paragraph>
          Some personal data from your SWITCH edu-ID account is automatically
          retrieved and displayed on your profile on the HEC-LABEX ORSEE
          recruitment platform. This includes the following personal data:
          <ul>
            <li>First and last name</li>
            <li>Email address</li>
            <li>Mobile phone number</li>
            <li>Date of birth</li>
          </ul>
          These data cannot be modified on your participant profile on the
          HEC-LABEX ORSEE recruitment platform but only on your SWITCH edu-ID
          account.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>
            B. User data on the HEC-LABEX ORSEE recruitment platform
          </strong>
          <br />
          When using the HEC-LABEX ORSEE recruitment platform, the following
          personal data about you is collected:
          <ul>
            <li>First and last name</li>
            <li>Email address</li>
            <li>Mother tongue</li>
            <li>Date of birth</li>
            <li>Gender</li>
            <li>Mobile phone number</li>
            <li>University</li>
            <li>Faculty</li>
            <li>Occupation (student or other)</li>
          </ul>
          In addition to this information, it is possible to enrich your
          participant profile with the following data:
          <ul>
            <li>Other language(s)</li>
            <li>Willingness to participate in online experiments (Yes/No)</li>
            <li>
              Banking information to facilitate bank transfers by the financial
              services (HEC and UNIL) in your favor:
              <ul>
                <li>
                  First and last name of the account holder (if it is a
                  different person from the participant)
                </li>
                <li>Address: street, postal code, city, and country</li>
                <li>IBAN</li>
                <li>SWIFT</li>
                <li>Account number</li>
                <li>Bank key</li>
                <li>Country of the bank</li>
              </ul>
            </li>
          </ul>
          This personal data is provided:
          <ol>
            <li>
              By you through the online registration form available on the
              HEC-LABEX ORSEE recruitment platform; or
            </li>
            <li>
              By you during your spontaneous participation (without prior
              registration) in one of our behavioral experiments, whether
              conducted in the laboratory or online. In this case, the personal
              data of your participant profile on the HEC-LABEX ORSEE
              recruitment platform is entered by HEC-LABEX staff.
            </li>
          </ol>
          Your participant profile on the HEC-LABEX ORSEE recruitment platform
          also includes participation status (presence, absence, dismissal) for
          all the experiments you have registered for.
          <br />
          The data on your profile on the HEC-LABEX ORSEE recruitment platform
          is not linked or interconnected with the data collected during
          experiments conducted by HEC-LABEX.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. For what purposes is your personal data processed by HEC-LABEX?
        </Typography>

        <Typography variant="body1" paragraph>
          Your personal data collected through the HEC-LABEX ORSEE recruitment
          platform is processed for the following purposes:
          <ul>
            <li>Organization of HEC-LABEX experiments;</li>
            <li>
              Recruitment of participants meeting the research criteria of a
              specific experiment;
            </li>
            <li>
              Sending invitations via email, providing information on new
              experiments conducted by HEC-LABEX;
            </li>
            <li>
              Sending information emails containing updates on the HEC-LABEX
              ORSEE recruitment platform, your participant profile, or
              HEC-LABEX;
            </li>
            <li>
              Creating a list of people interested in participating in HEC-LABEX
              experiments;
            </li>
            <li>
              Sending warning emails to participants who have registered but
              failed to show up for a HEC-LABEX experiment, as well as sending
              information related to registration or participation in an
              experiment (e.g., session cancellation, missing a signature on an
              official document during participation, etc.);
            </li>
            <li>
              Creating a list of individuals who should be paid by bank transfer
              for their participation in a HEC-LABEX experiment, whether in the
              laboratory or online;
            </li>
            <li>
              Verification of information by the HEC financial service and
              transmission of the list of individuals to be paid to the UNIL
              financial service for automatic bank transfer processing;
            </li>
            <li>
              Contacting participants via email if profile data needs to be
              corrected, and requesting correction of erroneous data. Upon
              request or confirmation by the participant, HEC-LABEX and/or the
              HEC financial service may correct the erroneous data in question.
            </li>
          </ul>
          The various processing activities conducted with your personal data
          correspond to the fulfillment of UNIL's public tasks, particularly the
          development of science through research. Your personal data is never
          sold to third parties, especially for marketing or promotional
          purposes.
          <br />
          Analyses on the data of participants in HEC-LABEX experiments are
          carried out to understand the characteristics of participants on the
          HEC-LABEX ORSEE recruitment platform. These analyses are conducted on
          fully anonymized data.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. To whom is your personal data communicated by HEC-LABEX?
        </Typography>

        <Typography variant="body1" paragraph>
          When you participate in an experiment, HEC-LABEX transmits your
          personal data to the individuals conducting the experiment in the
          HEC-LABEX laboratory (hereinafter: "experimenters") for the purposes
          of verifying attendance and checking the identity of participants.
        </Typography>

        <Typography variant="body1" paragraph>
          The experimenters provide a report to the HEC-LABEX administrative
          team regarding attendance, absence, dismissal, or any individual who
          behaved inappropriately during the experiment or who could jeopardize
          its smooth conduct.
        </Typography>

        <Typography variant="body1" paragraph>
          Additionally, when you participate in an experiment with participant
          payment via bank transfer, HEC-LABEX transmits your personal data to
          the financial services of HEC and UNIL for the purpose of processing
          the participation compensation via bank transfer.
        </Typography>

        <Typography variant="body1" paragraph>
          In accordance with Article 958f CO, the financial services of HEC and
          UNIL retain records of payments made and your personal data for a
          period of 10 years. After this period, your personal data will be
          deleted.
        </Typography>

        <Typography variant="body1" paragraph>
          The personal data processed by the HEC-LABEX ORSEE recruitment
          platform is used solely for the purpose of organizing behavioral
          experiments and is not shared with third parties unless you have
          expressly consented to this communication.
          <br />
          HEC-LABEX may be required to disclose your personal data as part of a
          civil, criminal, or administrative procedure, at the request of a
          judicial or administrative authority, or to comply with a legal
          obligation.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. How is your personal data protected by HEC-LABEX?
        </Typography>

        <Typography variant="body1" paragraph>
          HEC-LABEX takes all necessary measures to ensure the security of your
          personal data and to prevent unauthorized access, use, modification,
          or disclosure. HEC-LABEX ensures, to the extent possible, that any
          third party to whom your personal data is communicated handles it
          securely to ensure its confidentiality and integrity.
        </Typography>

        <Typography variant="body1" paragraph>
          The database of the HEC-LABEX ORSEE recruitment platform is stored on
          a secure server at UNIL. All access to personal data on the HEC-LABEX
          ORSEE recruitment platform is subject to the use of an individual
          password.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. How to modify and delete your personal data?
        </Typography>

        <Typography variant="body1" paragraph>
          At any time, you have the ability to review your participant profile
          on the HEC-LABEX ORSEE recruitment platform, unsubscribe from the
          HEC-LABEX invitation list, or modify the information about you on your
          participant profile by clicking on the link "Consult or modify your
          data" at the bottom of each invitation email to an experiment.
        </Typography>

        <Typography variant="body1" paragraph>
          Please note that certain data (first and last name, email address,
          mobile phone number, and date of birth) can only be modified on your
          SWITCH edu-ID account. Any modification of data on SWITCH edu-ID
          automatically updates your participant profile.
        </Typography>

        <Typography variant="body1" paragraph>
          In accordance with Article 25 LPrD, anyone can request access to their
          personal data, request its update, rectification, or deletion. To make
          such a request, you can contact HEC-LABEX by sending an email to:{" "}
          <a href="mailto:explab@unil.ch">explab@unil.ch</a>.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Contact
        </Typography>

        <Typography variant="body1" paragraph>
          For any questions regarding this privacy policy, you can contact:{" "}
          <a href="mailto:explab@unil.ch">explab@unil.ch</a>
        </Typography>

        <Typography variant="body1" paragraph>
          Last revision: June 2020
        </Typography>
      </Box>
    </Container>
  );
};

export default Policies;
