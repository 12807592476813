import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { Box, IconButton, Typography, styled } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BreadcrumbContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  height: "100%",
  padding: theme.spacing(2),
  boxShadow: theme.shadows[2],
  maxWidth: "100%",
  overflowX: "auto",
}));

const BreadcrumbItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& a": {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: theme.typography.subtitle1.fontSize,
    transition: "color 0.3s ease",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
}));

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Subbar = ({ url, id }) => {
  const capitalizedUrl = capitalizeFirstLetter(url);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: { xs: 1, sm: 2 },
        borderRadius: 20,
        maxWidth: "100%",
        marginX: "auto",
        height: "100%",
      }}
      display={"flex"}
      alignItems={"center"}
    >
      <BreadcrumbContainer>
        
        <BreadcrumbItem>
          <IconButton
            onClick={() => navigate(`/${url === "experience" ? "" : url}`)}
            aria-label="back"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`/${url === "experience" ? "" : url}`}>
            {capitalizedUrl}
          </Link>
        </BreadcrumbItem>
        <NavigateNextIcon
          sx={{ marginX: 1.5 }}
          fontSize="small"
          color="disabled"
        />
        <BreadcrumbItem>
          <Typography color="textPrimary" sx={{ fontWeight: 500 }}>
            {`${capitalizedUrl} ${id}`}
          </Typography>
        </BreadcrumbItem>
      </BreadcrumbContainer>
    </Box>
  );
};

export default Subbar;
