import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa6";
import { LuGroup, LuUngroup } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme/theme";
import { typeColumns } from "../utils/columnsType";
import InscriptionDialog from "./InscriptionDialog";

const Experiences = ({ data, type }) => {
  const [grouping, setGrouping] = useState(["experience"]);
  const [sorting, setSorting] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/experience/${row.original.experiment_id}`, {
      state: {
        type,
      },
    });
  };

  const handleCancel = (experience, event) => {
    event.stopPropagation();
    setSelectedExperience(experience);
    setOpenDialog(true);
  };
  const table = useReactTable({
    data,
    columns: typeColumns(type, handleCancel),
    state: {
      grouping,
      sorting,
      expanded,
    },
    onGroupingChange: (newGrouping) => {
      if (newGrouping.length > 1) {
        setGrouping([newGrouping[1]]);
      } else {
        setGrouping(newGrouping);
      }
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      expanded,
    },
  });

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedExperience(null);
  };

  const handleConfirm = () => {
    console.log(
      `Confirmed inscription for experience ID: ${selectedExperience.experiment_id}`
    );
    handleClose();
  };

  return (
    <Box
      sx={{
        padding: 2,
        "@media (max-width: 600px)": {
          
          padding: 0,
        },
      }}
    >
      <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
        <Table
          sx={{
            "@media (max-width: 600px)": {
              "& th, & td, & .font-box": {
                fontSize: "10px",
              },
              "& button": {
                padding: "2px",
                marginRight: "4px",
              },
              "& svg": {
                fontSize: "16px",
              },
            },
          }}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {header.column.getCanGroup() && (
                          <Button
                            onClick={header.column.getToggleGroupingHandler()}
                            sx={{
                              minWidth: 0,
                              padding: "4px",
                              marginRight: "8px",
                            }}
                          >
                            {header.column.getIsGrouped() ? (
                              <LuGroup size={20} />
                            ) : (
                              <LuUngroup size={20} />
                            )}
                          </Button>
                        )}
                        <TableSortLabel
                          active={!!header.column.getIsSorted()}
                          direction={
                            header.column.getIsSorted()
                              ? header.column.getIsSorted()
                              : "asc"
                          }
                          IconComponent={FaArrowUp}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </TableSortLabel>
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={
                  !row.getIsGrouped() ? () => handleRowClick(row) : undefined
                }
                sx={{
                  cursor: !row.getIsGrouped() ? "pointer" : "default",
                  "&:hover": {
                    backgroundColor:
                      !row.getIsGrouped() && theme.palette.primary.rgba,
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} sx={{ padding: "8px" }}>
                    {cell.getIsGrouped() ? (
                      <>
                        <Button
                          onClick={row.getToggleExpandedHandler()}
                          sx={{
                            minWidth: 0,
                            padding: "4px",
                            marginRight: "8px",
                          }}
                        >
                          {row.getIsExpanded() ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </Button>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}{" "}
                        ({row.subRows.length})
                      </>
                    ) : cell.getIsAggregated() ? (
                      flexRender(
                        cell.column.columnDef.aggregatedCell ??
                          cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <InscriptionDialog
        open={openDialog}
        onClose={handleClose}
        experience={selectedExperience}
        onConfirm={handleConfirm}
      />
    </Box>
  );
};

export default Experiences;
