import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(0, 140, 204, 1)",
      rgba: "rgba(0, 140, 204, 0.2)"
    },
    secondary: {
      main: "#edf2ff",
    },

  },
});
