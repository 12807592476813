import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import IBAN from "iban";
import React, { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../../api/axiosInstance";
import { useThunk } from "../../../hooks/useThunk";
import { getProfile } from "../../../store/thunks/user/getProfile";
import Loading from "../../other/Loading";
import Address from "./Address";
import PaymentInformation from "./PaymentInformation";
import PersonalInformation from "./PersonalInformation";
import PersonalInformationEdu from "./PersonalInformationEdu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const personalInformationSchema = Yup.object().shape({
  mother_tongue: Yup.string().trim().required("Mother tongue is required"),
  other_language: Yup.string().trim(),
  gender: Yup.string()
    .oneOf(["m", "f", "non-binary", "other"])
    .required("Gender is required"),
  institution: Yup.string().trim().required("Institution is required"),
  field_of_studies: Yup.string()
    .trim()
    .required("Field of studies is required"),
  begin_of_studies: Yup.number()
    .required("Begin of studies year is required")
    .min(1900, "Invalid year")
    .max(new Date().getFullYear(), "Year cannot be in the future"),
});

const addressSchema = Yup.object().shape({
  street_name: Yup.string().trim().required("Street is required"),
  postal_code: Yup.string().trim().required("Postal code is required"),
  city: Yup.string().trim().required("City is required"),
  country: Yup.string().trim().required("Country is required"),
});

const paymentInformationSchema = Yup.object().shape({
  is_account_holder_same_name: Yup.string()
    .oneOf(["y", "n"])
    .required("Account holder status is required"),

  iban: Yup.string().when("is_account_holder_same_name", {
    is: "y",
    then: (schema) =>
      schema
        .required("IBAN is required")
        .test("is-valid-iban", "Invalid IBAN", (value) => IBAN.isValid(value)),
    otherwise: (schema) =>
      schema.notRequired().test("is-valid-iban", "Invalid IBAN", (value) => IBAN.isValid(value)),
  }),

  account_holder_fname: Yup.string().when("is_account_holder_same_name", {
    is: "n",
    then: (schema) => schema.required("First name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  account_holder_lname: Yup.string().when("is_account_holder_same_name", {
    is: "n",
    then: (schema) => schema.required("Last name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  country_of_bank: Yup.string().required("Bank country is required"),

  account_number: Yup.string().when("country_of_bank", {
    is: (value) => value !== "CH",
    then: (schema) => schema.required("Account number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  swift: Yup.string().when("country_of_bank", {
    is: (value) => value !== "CH",
    then: (schema) => schema.required("BIC (SWIFT) code is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  bank_clearing: Yup.string().when("country_of_bank", {
    is: (value) => value !== "CH",
    then: (schema) => schema.required("Bank clearing number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const ProfileDashboardWeb = () => {
  const [navigation, setNavigation] = useState(0);
  const { userInfo } = useSelector((state) => state.user);
  const [form, setForm] = useState([]);
  const [errors, setErrors] = useState({});
  const [eduForm, setEduForm] = useState({});
  const [runGetProfile, isLoadingProfile, errorProfile] = useThunk(getProfile);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const isNotMobile = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (userInfo.length === 0) {
      runGetProfile();
    }
  }, []);

  useEffect(() => {
    if (userInfo.length !== 0) {
      setForm(userInfo?.slice(0, 3));
      setEduForm(userInfo[3]);
    }
  }, [userInfo]);

  useEffect(() => {
    if (form.length !== 0) {
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[navigation] = form[navigation];
        return updatedForm;
      });
      setErrors({});
    }
  }, [navigation]);

  const handleNavigationChange = (event, newValue) => {
    setNavigation(newValue);
  };

  const handleDataChange = (newData) => {
    if (newData?.target?.name === "is_account_holder_same_name") {
      const { name, value } = newData.target;
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[navigation] = {
          ...updatedForm[navigation],
          [name]: value,
        };
        return updatedForm;
      });
    } else {
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[navigation] = newData;
        return updatedForm;
      });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const dataToValidate = {
      personal: form[0],
      address: form[1],
      paymentInformation: form[2],
    };

    try {
      if (navigation === 0) {
        await personalInformationSchema.validate(dataToValidate.personal, {
          abortEarly: false,
        });
      }
      if (navigation === 1) {
        await addressSchema.validate(dataToValidate.address, {
          abortEarly: false,
        });
      } else if (navigation === 2) {
        await paymentInformationSchema.validate(
          dataToValidate.paymentInformation,
          { abortEarly: false }
        );
      }
      setErrors({});

      console.log(form[2])
      const formData = new FormData();

      Object.keys(userInfo[4]).forEach((key) => {
        formData.append(key, userInfo[4][key]);
      });

      Object.keys(form[navigation]).forEach((key) => {
        formData.append(key, form[navigation][key]);
      });

      const data = await axiosInstance.post("/participant/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (isNotMobile) {
        toast.success("Your changes have been saved successfully.");
      }
    } catch (error) {
      console.log(error)
      if (error?.inner) {
        const validationErrors = {};

        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      }

      if (isNotMobile) {
        toast.error("An error occurred while saving your changes.");
      }
    } finally {
      setLoading(false);
    }
  };

  if (form.length === 0 || isLoadingProfile) {
    return <Loading />;
  }

  return (
    <Container
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        maxWidth: "1400px !important",
      }}
    >
      <ToastContainer />
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "20px",
            borderRadius: "8px",
            marginRight: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            mb={2}
            p={2}
            sx={{ backgroundColor: "#e3f2fd", borderRadius: "8px" }}
          >
            <Typography variant="body2" color="primary">
              The following fields can only be modified on SWITCH edu-ID.
            </Typography>
          </Box>
          <Box mb={2} sx={{ flexGrow: 1 }}>
            <PersonalInformationEdu data={eduForm} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md
          sx={{
            backgroundColor: "#e0f7fa",
            padding: "20px",
            borderRadius: "8px",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: 5 }}
          >
            <Tabs value={navigation} onChange={handleNavigationChange}>
              <Tab label="Personal" />
              <Tab label="Address" />
              <Tab label="Payment" />
            </Tabs>
            <Button
              variant="contained"
              startIcon={<IoIosSave />}
              sx={{ textTransform: "none" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {navigation === 0 && (
              <PersonalInformation
                data={form[0]}
                setData={handleDataChange}
                errors={errors}
              />
            )}
            {navigation === 1 && (
              <Address
                data={form[1]}
                setData={handleDataChange}
                errors={errors}
              />
            )}
            {navigation === 2 && (
              <PaymentInformation
                data={form[2]}
                personalInfo={eduForm}
                setData={handleDataChange}
                errors={errors}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileDashboardWeb;
