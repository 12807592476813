import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Subbar from "../navigation/Subbar";
import { checkUrl } from "../utils/checkUrl";
import { extractRoomUrl } from "../utils/extractRoomUrl";
import { extractDateTime } from "../utils/extractDateTime";
import useExperienceData from "../../hooks/useExperienceData";
import { useLocationRoom } from "../../hooks/useLocationRoom";
import { useThunk } from "../../hooks/useThunk";
import { getTranslations } from "../../store/thunks/collections/getTranslations";
import Loading from "../other/Loading";
import { getStatusExperience } from "../utils/getStatus";

import "../../style/Map.css";
const retrieveArrayExperience = (type) => {
  switch (type) {
    case "done":
      return "doneExperiences";
    case "invitation confirmed":
      return "experienceRegistrations";
    case "payment":
      return "confirmationExperiences";

    default:
      return "doneExperiences";
  }
};

const ExperienceInformation = ({ data }) => {
  const { id } = useParams();
  const location = useLocation();
  const { type } = useLocation().state;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    confirmationExperiences,
    loading,
    error,
  } = useExperienceData();
  const [runGetTranslations, isLoadingField, errorField] =
    useThunk(getTranslations);
  const experiences = useSelector((state) => state.experience);
  const experience = experiences[retrieveArrayExperience(type)].find((exp) => {
    return exp.experiment_id.toString() === id;
  });

  const {
    loading: fieldLoading,
    fields,
    lang,
  } = useSelector((state) => state.collections);

  useEffect(() => {
    if (fields.length && !fieldLoading) {
      runGetTranslations();
    }
  }, []);

  


  const url = checkUrl(location);

  if (loading) {
    return <Loading />;
  }
  if (!experience) {
    return <Typography variant="h6">Experience not found</Typography>;
  }

  return (
    <Box padding={2}>
      <Subbar url={url} id={experience?.experiment_public_name} />
      <Box sx={{ padding: 2 }}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Experience Information
          </Typography>
          <Box marginTop={5} />
          <Box
            display={"flex"}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box width={isSmallScreen ? "100%" : "50%"}>
              <Box
                sx={{ marginBottom: 2, width: "100%" }}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography style={{ width: "50%" }} variant="h6">
                  Experience
                </Typography>
                <Typography style={{ width: "50%" }}>
                  {experience?.experiment_public_name}
                </Typography>
              </Box>
              <Box
                sx={{ marginBottom: 2, width: "100%" }}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography style={{ width: "50%" }} variant="h6">
                  Date
                </Typography>
                <Typography style={{ width: "50%" }}>
                  {`${extractDateTime(experience?.session_name).date} - ${
                    extractDateTime(experience?.session_name).time
                  }`}
                </Typography>
              </Box>
              <Box
                sx={{ marginBottom: 2, width: "100%" }}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography style={{ width: "50%" }} variant="h6">
                  Location
                </Typography>
                <Typography style={{ width: "50%" }} variant="body1">
                  {experience.lab_name || "N/A"}
                </Typography>
              </Box>
              <Box
                sx={{ marginBottom: 2, width: "100%" }}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography style={{ width: "50%" }} variant="h6">
                  Status
                </Typography>
                <Typography
                  style={{ width: "50%" }}
                  variant="body1"
                  sx={{ color: getStatusColor(experience?.pstatus_id) }}
                >
                  {getStatusExperience(experience?.pstatus_id)}
                </Typography>
              </Box>
            </Box>

            {extractRoomUrl(experience?.lab_name, fields, lang).url && (
              <Box
                width={isSmallScreen ? "100%" : "50%"}
                minHeight={400}
                mt={isSmallScreen ? 2 : 0}
              >
                <iframe
                  id="iframe-id"
                  src={extractRoomUrl(experience?.lab_name, fields, lang).url}
                  style={{
                    border: 0,
                    width: "100%",
                    height: isSmallScreen ? "50vh" : "100%",
                  }}
                  title="Experience Location"
                ></iframe>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 0:
      return "orange";
    case 1:
      return "green";
    case "Cancelled":
      return "red";
    default:
      return "black";
  }
};

export default ExperienceInformation;
