import React from "react";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import Header from "./Header";
import Login from "../login/Login";

const Layout = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#f0f0f0",
          padding: {
            sm: "0px",
          },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
