import { Box, Button, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { extractDateTime } from "./extractDateTime";
import { getStatusExperience, getStatusPayment } from "./getStatus";

const columnHelper = createColumnHelper();

const typeColumns = (type, fc) => {
  switch (type) {
    case "payment":
      return [
        columnHelper.accessor("experiment_public_name", {
          header: "Experience",
          cell: (info) => info.getValue(),
          enableSorting: true,
          enableGrouping: true,
        }),
        columnHelper.accessor("payment_amt", {
          header: "Amount",
          enableSorting: true,
          enableGrouping: false,
          cell: (info) => {
            return (
              <Typography className="font-box">{info.getValue()}.-</Typography>
            );
          },
          aggregatedCell: (info) => {
            return (
              <Typography className="font-box">{info.getValue()}.-</Typography>
            );
          },
        }),
        columnHelper.accessor("pstatus_id", {
          header: "Status",
          enableSorting: true,
          cell: (info) => {
            const value = info.getValue();
            let color;
            switch (value) {
              case 0:
                color = "orange";
                break;
              case 1:
              case 2:
                color = "green";
                break;
                color = "red";
                break;
              default:
                color = "black";
            }
            return (
              <Typography className="font-box" style={{ color }}>
                {getStatusPayment(value)}
              </Typography>
            );
          },
        }),
      ];
    case "invitation confirmed":
      return [
        columnHelper.accessor("experiment_public_name", {
          header: "Experience",
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor("session_name", {
          header: "Date",
          enableSorting: true,
          enableGrouping: false,
          cell: (info) => (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Box className="font-box" fontSize={15} color={"#003366"}>
                {extractDateTime(info.getValue()).date}
              </Box>
              <Box
                className="font-box"
                fontWeight={600}
                fontSize={15}
                color={"#6699CC"}
              >
                {extractDateTime(info.getValue()).time}

              </Box>
            </Box>
          ),
        }),
        columnHelper.accessor("lab_name", {
          header: "Location",
          enableSorting: true,
          enableGrouping: false,
        }),
        {
          id: "cancel",
          header: "",
          cell: ({ row }) => (
            <Button
              variant="contained"
              color="error"
              onClick={(event) => fc(row.original, event)}
              size="small"
            >
              Cancel
            </Button>
          ),
        },
      ];

    case "invitation":
    case "done":
      return [
        columnHelper.accessor("experiment_public_name", {
          header: "Experience",
          cell: (info) => info.getValue(),
          enableSorting: true,
          enableGrouping: true,
        }),
        columnHelper.accessor("session_name", {
          header: "Date",
          enableSorting: true,
          enableGrouping: false,
          cell: (info) => (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Box className="font-box" fontSize={15} color={"#003366"}>
              {extractDateTime(info.getValue()).date}

              </Box>
              <Box
                className="font-box"
                fontWeight={600}
                fontSize={15}
                color={"#6699CC"}
              >
                {extractDateTime(info.getValue()).time}

              </Box>
            </Box>
          ),
        }),
        columnHelper.accessor("lab_name", {
          header: "Location",
          enableSorting: true,
          enableGrouping: false,
        }),
        columnHelper.accessor("pstatus_id", {
          header: "Status",
          enableSorting: true,
          cell: (info) => {
            const value = info.getValue();
            let color;
            switch (value) {
              case 0:
                color = "orange";
                break;
              case 1:
              case 2:
                color = "green";
                break;
              default:
                color = "black";
            }
            return (
              <Typography className="font-box" style={{ color }}>
                {getStatusExperience(value)}
              </Typography>
            );
          },
        }),
      ];

    default:
      break;
  }
};

export { typeColumns };
