import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

export const getTranslations = createAsyncThunk(
  "collections/getTranslations",
  async (credentials, thunkAPI) => {
    try {
      const { lang } = thunkAPI.getState().collections;
      let { data } = await axiosInstance.get(`/${lang}`);
      data = JSON.parse(data);
      const translationsObject = data.reduce((acc, item) => {
        acc[item.content_type] = item[lang];
        return acc;
      }, {});

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
