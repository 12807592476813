import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

export default function FAQ() {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Here are some common questions about participating in our experiments.
        </Typography>
      </Box>
      <Divider sx={{ mb: 4 }} />
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            Am I paid to participate in the experiments? If so, how much?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            In principle, we pay for each participation in an experiment. Each
            participant is remunerated in cash at the end of the experiment or
            by bank transfer.
            <br />
            <br />
            The amount of compensation depends on the nature of the experiment,
            your decisions, and the decisions of the other participants. For
            scientific reasons, we do not provide advance information on the
            amount of compensation. This information will be communicated to you
            at the beginning of the experiment session, at the time of signing
            your consent to participate.
            <br />
            <br />
            As a guide: the duration of the experiments varies between 30
            minutes and 2 hours for compensation per hour corresponding to
            approximately an average student salary, although this may vary from
            one research to another. The fact that most participants wish to
            take part in new experiments proves that the compensation is fair.
            <br />
            <br />
            It is especially important to note that since you are being
            compensated, we expect you to perform the tasks requested as
            conscientiously as possible in return.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            Am I required to show up every time I receive an invitation?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            You will receive an invitation by email if your profile matches the
            research criteria of ongoing experiments.
            <br />
            <br />
            When you are invited to an experiment, you are free to participate
            or not, depending on your availability. However, you can only
            participate if you have explicitly confirmed your participation.
            This confirmation is made by clicking on the link in the invitation
            email and choosing one of the proposed dates. You will then receive
            an email confirming your registration for the date you have chosen.
            <br />
            <br />
            Once registered for an experiment session, you must show up at the
            exact time or notify us by email as soon as possible of your absence
            for exceptional reasons. If you arrive late, the researchers may
            dismiss you without compensation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>How does a laboratory experiment work?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            We conduct several types of experiments: computer-based games,
            virtual reality, online questionnaires, or smartphone apps. This
            will be specified in the invitation email.
            <br />
            <br />
            In the case of a laboratory study, most of the time, the experiment
            takes place in one of our labs on a computer. You will sit in front
            of a PC and first read some instructions. Everything you need to
            know to participate in the study will be explained there. The
            experiment then begins.
            <br />
            <br />
            You will be confronted with several situations during which you will
            have to make decisions. At the end of the experiment, you will need
            to answer a questionnaire, sometimes immediately after the session,
            sometimes from home a few days later.
            <br />
            <br />
            You will receive your payment by bank transfer or in cash, depending
            on the modalities explained at the beginning of the experiment.
            <br />
            <br />
            Remember that these experiments are not exams; therefore, there are
            no right or wrong answers. The researchers are more interested in
            your behavior and decisions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            How can I cancel my registration for an experiment?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            There is no option to cancel a registration by yourself. Therefore,
            you should carefully check whether you are available for an
            experiment BEFORE registering. However, if you become ill or are
            unable to attend due to an unforeseen event, please contact us by
            email AS SOON AS POSSIBLE to give us enough time to find another
            participant. We will then cancel your registration.
            <br />
            <br />
            Under no circumstances should you send someone else in your place
            without informing the LABEX coordination team well in advance.
            <br />
            <br />
            The experiment may require participation criteria of which you are
            unaware, and sending someone else could skew the results if these
            criteria are not met.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>Who funds our experiments?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Our experiments are funded by various research grants. We conduct
            independent scientific research.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            How can I register to participate in the experiments?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Create your participant profile in the ORSEE database.
            <br />
            <br />
            To continue receiving invitations even after your studies, it is
            best to use a long-term email address (i.e., not a company or
            university email address).
            <br />
            <br />
            Confirm the creation of your participant profile via the link you
            will receive by email. If you do not receive a confirmation email,
            please check your spam folder.
            <br />
            <br />
            Once your ORSEE profile is confirmed, you will receive email
            invitations whenever we are recruiting participants, and your
            profile matches the criteria of the ongoing research.
            <br />
            <br />
            The invitation email will contain the participation conditions,
            dates and times of the experiment, the duration, and a registration
            link that will allow you to sign up for a specific time slot.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            What happens if I intentionally provide false information when
            creating my participant profile?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Never do this. This information is crucial for experimental
            research. You will not be allowed to participate in an experiment if
            your profile is found to be incorrect. If you do not wish to provide
            certain requested information, leave the field blank. However, be
            aware that the more information you provide, the more likely you are
            to receive invitations to participate in experiments.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9a-content"
          id="panel9a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            Can I register for multiple sessions of the same experiment?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Most experiments consist of several sessions on the same day or over
            several days/weeks. However, each person can only participate ONCE
            in each experiment. Therefore, it is not possible to register for
            multiple sessions, and it is FORBIDDEN to attend more than one
            session of the same experiment.
            <br />
            <br />
            This does not apply to experiments that have multiple waves, which
            require registration for several sessions and are specifically
            designated as such.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10a-content"
          id="panel10a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            What is a behavioral economics laboratory experiment?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Laboratory studies are one of the methods of empirical research in
            economics. Your participation helps to better understand certain
            aspects of how the economy functions. Through laboratory studies,
            researchers can systematically study complex interactions between
            individuals under controlled conditions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11a-content"
          id="panel11a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            What does my "status" as a participant mean in the "Experiments you
            have participated in" section?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Your participation status in an experiment is updated after each
            session of that experiment and/or upon its completion. There are
            three statuses:
            <br />
            <br />
            (1) Participated: You attended the session and participated in it;
            <br />
            <br />
            (2) Attended: You attended the session, but could not participate
            because the session was overbooked or had to be canceled;
            <br />
            <br />
            (3) Absent: You did not attend the session and did not excuse
            yourself at least 12 hours in advance for your exceptional absence.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12a-content"
          id="panel12a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            What happens if I do not attend a session without properly canceling
            at least 12 hours in advance?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Participants who sign up for a session must keep in mind that the
            session may be canceled if they do not show up or arrive late, which
            penalizes everyone. Therefore, after 3 UNANNOUNCED ABSENCES PER
            SEMESTER, you will be REMOVED from our database.
            <br />
            <br />
            If you become ill or are unable to attend due to an unforeseen
            event, please contact us by email AS SOON AS POSSIBLE. We will then
            cancel your registration.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12a-content"
          id="panel12a-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>
            What is the difference between behavioral economics research and
            neuroeconomics?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography>
            Neuroeconomics is a research field at the intersection of economics
            and cognitive neuroscience that studies the influence of cognitive
            and emotional factors in decision-making.
            <br />
            <br />
            It is closely related to behavioral economics, with the difference
            being that behavioral economics focuses more on the individual and
            collective behaviors of economic agents, while neuroeconomics
            examines the neurobiological bases of these behaviors, particularly
            through brain imaging techniques. For this reason, neuroeconomic
            experiments are generally more complex than standard behavioral
            experiments.
            <br />
            <br />
            Each neuroeconomic experiment is subject to review and approval by
            the cantonal ethics commission.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-switch-edu-content"
          id="panel-switch-edu-header"
          sx={{ bgcolor: "primary.main", color: "white" }}
        >
          <Typography>How to authenticate with SWITCH-edu ID?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper", p: 3 }}>
          <Typography gutterBottom>
            To authenticate with SWITCH-edu ID, please follow the instructions
            in the video below:
          </Typography>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              paddingTop: "56.25%", 
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <iframe
              src="https://tube.switch.ch/embed/4357455c"
              title="SWITCH-edu ID Authentication"
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "inherit",
              }}
            ></iframe>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body1">
          For any questions, please contact <a href="mailto:explab@unil.ch">explab@unil.ch</a>.
        </Typography>
      </Box>
    </Container>
  );
}
