import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import ExperienceDashboard from "./components/experience/ExperienceDashboard";
import ExperienceInformation from "./components/experience/ExperienceInformation";
import Experiences from "./components/experience/Experiences";
import FAQ from "./components/help/FAQ";
import RulesManager from "./components/help/RulesManager";
import Layout from "./components/navigation/Layout";
import ProfileManager from "./components/profile/ProfileManager";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import useExperienceData from "./hooks/useExperienceData";
import { useThunk } from "./hooks/useThunk";
import { getProfile } from "./store/thunks/user/getProfile";
import { getTranslations } from "./store/thunks/collections/getTranslations";
import LandingPage from "./components/login/LandingPage";
import { setAuth } from "./store/slice/userSlice";

function App() {
  const isInitialized = useRef(false);
  const { userInfo } = useSelector((state) => state.user);
  const [runGetProfile, isLoading, errorProfile] = useThunk(getProfile);
  const [runGetTranslations, isLoadingField, errorField] =
    useThunk(getTranslations);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isInitialized.current && userInfo.length === 0 && !isLoading) {
      runGetProfile();
      runGetTranslations();
      isInitialized.current = true;
    }
  }, [runGetProfile, userInfo.length]);

  useEffect(() => {
    if (localStorage.getItem("jwtToken")) {
      dispatch(setAuth());
    }
  }, []);

  return (
    <Router basename="/orsee">
      <Layout>
        <Routes>
          {!isAuthenticated && <Route path="/" element={<LandingPage />} />}
          <Route path="/rules&policies" element={<RulesManager />} />
          <Route path="/help" element={<FAQ />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<ExperienceDashboard />} />

            <Route path="/experience/:id" element={<ExperienceInformation />} />
            <Route path="/profile" element={<ProfileManager />} />
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
