import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";

const languages = [
  "English",
  "French",
  "German",
  "Spanish",
  "Italian",
  "Chinese",
  "Japanese",
  "Russian",
  "Portuguese",
  "Arabic",
  "Hindi",
  "Bengali",
  "Korean",
  "Vietnamese",
  "Turkish",
  "Persian",
  "Dutch",
  "Thai",
  "Greek",
  "Swedish",
  "Norwegian",
  "Danish",
  "Finnish",
  "Hebrew",
  "Polish",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 500,
    },
  },
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= 8; i++) {
    years.push(currentYear - i);
  }
  return years;
};

const PersonalInformation = ({ data, setData, errors }) => {
  const { lang, fields } = useSelector((state) => state.collections);

  const institutionContentTypes = fields
    ?.filter((item) => item.content_type.includes("institution"));

  const fieldOfStudiesContentTypes = fields
    ?.filter((item) => item.content_type.includes("field_of_studies"));

  const motherTongueContentTypes = fields
    ?.filter((item) => item.content_type.includes("mother_tongue"));

  const handleDropdownChange = (event, fieldName, contentTypes) => {
    const { value } = event.target;
    const selectedItem = contentTypes.find(
      (item) => item.content_name === value
    );
    setData({
      ...data,
      [fieldName]: value,
      [`${fieldName}_label`]: selectedItem ? selectedItem[lang] : "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Container sx={{ padding: "20px" }}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          padding: "20px",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontSize: "1rem" }}>
          Personal Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ fontSize: "0.875rem" }}
              error={!!errors?.mother_tongue}
            >
              <InputLabel>Mother Tongue</InputLabel>
              <Select
                name="mother_tongue"
                value={data.mother_tongue}
                onChange={(event) =>
                  handleDropdownChange(
                    event,
                    "mother_tongue",
                    motherTongueContentTypes
                  )
                }
                label="Mother Tongue"
                MenuProps={MenuProps}
              >
                {motherTongueContentTypes.map((language) => (
                  <MenuItem
                    key={language.content_name}
                    value={language.content_name}
                  >
                    {language[lang]}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.mother_tongue && (
                <Typography variant="caption" color="error">
                  {errors.mother_tongue}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Other Language"
              variant="outlined"
              fullWidth
              margin="normal"
              name="other_language"
              value={data.other_language || ""}
              onChange={handleInputChange}
              select
              sx={{ fontSize: "0.875rem" }}
              SelectProps={{
                MenuProps: MenuProps,
              }}
              error={!!errors?.other_language}
              helperText={errors?.other_language}
            >
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ fontSize: "0.875rem" }}
              error={!!errors?.gender}
            >
              <InputLabel>Gender</InputLabel>
              <Select
                name="gender"
                value={data.gender}
                onChange={handleInputChange}
                label="Gender"
              >
                <MenuItem value="m">Male</MenuItem>
                <MenuItem value="f">Female</MenuItem>
                <MenuItem value="non-binary">Non-binary</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              {!!errors?.gender && (
                <Typography variant="caption" color="error">
                  {errors.gender}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ fontSize: "0.875rem" }}
              error={!!errors?.institution}
            >
              <InputLabel>Institution</InputLabel>
              <Select
                name="institution"
                value={data.institution}
                onChange={(event) =>
                  handleDropdownChange(
                    event,
                    "institution",
                    institutionContentTypes
                  )
                }
                label="Institution"
                MenuProps={MenuProps}
              >
                {institutionContentTypes.map((institution) => (
                  <MenuItem
                    key={institution.content_name}
                    value={institution.content_name}
                  >
                    {institution[lang]}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.institution && (
                <Typography variant="caption" color="error">
                  {errors.institution}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ fontSize: "0.875rem" }}
              error={!!errors?.field_of_studies}
            >
              <InputLabel>Field of Studies</InputLabel>
              <Select
                name="field_of_studies"
                value={data.field_of_studies}
                onChange={(event) =>
                  handleDropdownChange(
                    event,
                    "field_of_studies",
                    fieldOfStudiesContentTypes
                  )
                }
                label="Field of Studies"
                MenuProps={MenuProps}
              >
                {fieldOfStudiesContentTypes.map((field) => (
                  <MenuItem
                    key={field.content_name}
                    value={field.content_name}
                  >
                    {field[lang]}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.field_of_studies && (
                <Typography variant="caption" color="error">
                  {errors.field_of_studies}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ fontSize: "0.875rem" }}
              error={!!errors?.begin_of_studies}
            >
              <InputLabel>Study Start Year</InputLabel>
              <Select
                name="begin_of_studies"
                value={data.begin_of_studies}
                onChange={handleInputChange}
                label="Study Start Year"
                MenuProps={MenuProps}
              >
                {getYears().map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.begin_of_studies && (
                <Typography variant="caption" color="error">
                  {errors.begin_of_studies}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PersonalInformation;
