import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { extractRoomUrl } from "../utils/extractRoomUrl";
import { useSelector } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import { getTranslations } from "../../store/thunks/collections/getTranslations";

const LocationDialog = ({ open, onClose, location }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    loading: fieldLoading,
    fields,
    lang,
  } = useSelector((state) => state.collections);
  const [runGetTranslations, isLoadingField, errorField] =
    useThunk(getTranslations);

  useEffect(() => {
    if (fields.length && !fieldLoading) {
      runGetTranslations();
    }
  }, []);

  if (!location) {
    return <></>;
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>Experience Location</DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 3 }}>
        <Box
          sx={{
            mt: 3,
            mb: 3,
            boxShadow: 3,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {extractRoomUrl(location, fields, lang).url && (
            <Box
              width={isSmallScreen ? "100%" : "50%"}
              minHeight={400}
              mt={isSmallScreen ? 2 : 0}
            >
              <iframe
                id="iframe-id"
                src={extractRoomUrl(location, fields, lang).url}
                style={{
                  border: 0,
                  width: "100%",
                  height: isSmallScreen ? "50vh" : "100%",
                }}
                title="Experience Location"
              ></iframe>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LocationDialog;
