import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { extractDateTime } from "../utils/extractDateTime";
import { extracDeadLineTime } from "../utils/extractDeadLineTime";
import InscriptionDialog from "./InscriptionDialog";
import LocationDialog from "./LocationDialog";

const Invitation = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = (experience) => {
    setSelectedExperience(experience);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedExperience(null);
  };

  const handleConfirm = async () => {
    handleClose();
  };

  const handleClickOpenLocation = (location) => {
    setSelectedLocation(location);
    setOpenLocation(true);
  };

  const handleCloseLocation = () => {
    setOpenLocation(false);
    setSelectedLocation(null);
  };

  return (
    <Box sx={{ padding: isMobile ? 1 : 2 }}>
      <Paper sx={{ padding: isMobile ? 1 : 2 }}>
        <List>
          {data.map((exp, index) => (
            <React.Fragment key={exp.id}>
              <ListItem
                sx={{
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {exp.experiment_public_name}
                  </Typography>
                  <Grid container spacing={isMobile ? 0 : 2}>
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        <strong>Inscription Deadline:</strong>

                        {extracDeadLineTime(exp.registration_unixtime)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Date:</strong>{" "}
                        {`${extractDateTime(exp.session_name).date} - ${
                          extractDateTime(exp.session_name).time
                        }`}
                      </Typography>
                      <Box variant="body1" display={"flex"}>
                        <strong>Location:</strong>{" "}
                        <Typography
                          onClick={() => {
                            handleClickOpenLocation(exp.lab_name);
                          }}
                          sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: theme.palette.primary.main,
                            marginLeft: 1,
                          }}
                        >
                          {exp.lab_name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    marginLeft: isMobile ? 0 : 2,
                    marginTop: isMobile ? 2 : 0,
                    fontSize: isMobile ? 13 : 14,
                    padding: isMobile ? "6px 12px" : "8px 16px",
                  }}
                  onClick={() => handleClickOpen(exp)}
                >
                  Inscription
                </Button>
              </ListItem>
              {index !== data.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>

      <InscriptionDialog
        open={open}
        onClose={handleClose}
        experience={selectedExperience}
        onConfirm={handleConfirm}
        type={"confirm"}
      />
      <LocationDialog
        open={openLocation}
        onClose={handleCloseLocation}
        location={selectedLocation}
      />
    </Box>
  );
};

export default Invitation;
