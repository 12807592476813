import React from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { countries } from "../../utils/countryList";

const Address = ({ data, setData, errors }) => {
  const handleChange = (event, value) => {
    if (value) {
      setData({ ...data, country: value.code });
    } else {
      setData({ ...data, country: "" });
    }
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          padding: "20px",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontSize: "1rem" }}>
          Mailing Address
        </Typography>
        <TextField
          label="Street Name and Number"
          variant="outlined"
          fullWidth
          margin="normal"
          name="street_name"
          value={data.street_name}
          onChange={(event) => setData({ ...data, street_name: event.target.value })}
          sx={{ fontSize: "0.875rem" }}
          error={!!errors?.street_name} 
          helperText={errors?.street_name} 
        />
        <TextField
          label="Postal Code"
          variant="outlined"
          fullWidth
          margin="normal"
          name="postal_code"
          value={data.postal_code}
          onChange={(event) =>
            setData({ ...data, postal_code: event.target.value })
          }
          sx={{ fontSize: "0.875rem" }}
          error={!!errors?.postal_code}
          helperText={errors?.postal_code}
        />
        <TextField
          label="City"
          variant="outlined"
          fullWidth
          margin="normal"
          name="cityy"
          value={data.city}
          onChange={(event) => setData({ ...data, city: event.target.value })}
          sx={{ fontSize: "0.875rem" }}
          error={!!errors?.city}
          helperText={errors?.city}
        />
        <Autocomplete
          id="country-select"
          options={countries}
          getOptionLabel={(option) => option.code}
          isOptionEqualToValue={(option, value) => option.code === value}
          value={countries.find((c) => c.code === data.country) || null}
          onChange={handleChange}
          filterOptions={(options, state) =>
            options.filter(
              (option) =>
                option.label
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase()) ||
                option.code
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
            )
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              margin="normal"
              fullWidth
              inputProps={{
                ...params.inputProps,
              }}
              error={!!errors?.country} 
              helperText={errors?.country} 
            />
          )}
        />
      </Box>
    </Container>
  );
};

export default Address;
