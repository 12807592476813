import MenuIcon from "@mui/icons-material/Menu";
import { Button, Tab, Tabs } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slice/userSlice";

const pages = ["Experience", "Profile", "Rules&Policies", "Help"];
const allowedUrlConnected = ["experience", "profile", "rules&policies", "help"];
const allowedUrlNotConnected = ["rules&policies", "help"];
function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const { handleLogin } = useLogin();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("selectedTab", 0);
    }
  }, [isAuthenticated]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const getInitialValue = () => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab !== null) {
      return Number(savedTab);
    } else {
      return 0;
    }
  };

  const [value, setValue] = useState(getInitialValue());

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    console.log(savedTab);
    if (savedTab === null) {
      const initialTabIndex = getTabIndexFromPath(isAuthenticated, pathname);
      setValue(initialTabIndex);
      localStorage.setItem("selectedTab", initialTabIndex);
    }
  }, [pathname, isAuthenticated]);

  const getTabIndexFromPath = (isAuthenticated, pathname) => {
    const allowedUrls = isAuthenticated
      ? allowedUrlConnected
      : allowedUrlNotConnected;
    console.log(getTabIndexFromPath);

    console.log(isAuthenticated);

    const matchedIndex = allowedUrls.findIndex((allowedPath) => {
      if (
        allowedPath === "experience" &&
        (pathname === "/" || pathname.startsWith("/experience"))
      ) {
        return true;
      }
      return pathname.includes(allowedPath);
    });

    return matchedIndex !== -1 ? matchedIndex : 0;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  console.log(getInitialValue(), value);

  return (
    <AppBar position="sticky" sx={{ backgroundColor: `rgba(0, 140, 204, 1)` }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters={true}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              marginLeft: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("selectedTab", "/");
              setValue("/");
              navigate("/");
            }}
          >
            Orsee
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              marginLeft: 50,
              cursor: "pointer",
            }}
          >
            Orsee
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              boxShadow: "none",
            }}
          >
            {isAuthenticated ? (
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    width: 3,
                    backgroundColor:
                      localStorage.getItem("selectedTab") && value === "/"
                        ? "none"
                        : "#fff",
                    display: "none",
                  },
                }}
                sx={{ boxShadow: "none" }}
              >
                <Tab
                  label="Experience"
                  onClick={() => {
                    navigate("/");
                  }}
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                />
                <Tab
                  label="Profile"
                  onClick={() => {
                    navigate("/profile");
                  }}
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                />

                <Tab
                  label="Rules & Policies"
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    navigate("/rules&policies");
                  }}
                />
                <Tab
                  label="Help"
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    navigate("/help");
                  }}
                />
              </Tabs>
            ) : (
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    width: 3,
                    backgroundColor:
                      localStorage.getItem("selectedTab") && value === "/"
                        ? "none"
                        : "#fff",
                    display: "none",
                  },
                }}
                sx={{ boxShadow: "none" }}
              >
                <Tab
                  label="Help"
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    navigate("/help");
                  }}
                />
              </Tabs>
            )}
          </Box>
          {!isAuthenticated && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <Button
                variant="contained"
                onClick={handleLogin}
                sx={{
                  backgroundColor: "white",
                  color: "primary.main",
                  marginRight: 25,
                  padding: "8px 16px",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontWeight: "bold",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "grey.200",
                    color: "primary.main",
                  },
                }}
              >
                Login
              </Button>
            </Box>
          )}

          {isAuthenticated && (
            <Box sx={{ flexGrow: 0 }} marginRight={10}>
              <Tooltip title="Open Menus">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key={"profile"}
                  onClick={() => {
                    setAnchorElUser(null);
                    navigate("/profile");
                  }}
                >
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem
                  key="logout"
                  onClick={() => {
                    dispatch(logout());
                    setAnchorElUser(null);
                  }}
                  sx={{ color: "red" }}
                >
                  <Typography textAlign="center">Logout</Typography>
                  <FiLogOut style={{ marginLeft: 8 }} />
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
