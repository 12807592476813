import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slice/userSlice';
import experienceSlice from './slice/experienceSlice';
import collectionsSlice from './slice/collectionsSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    experience:experienceSlice,
    collections:collectionsSlice
  },
});

export default store;