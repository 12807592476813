import { Box, Container, Paper, Typography } from "@mui/material";
import React from "react";
import useExperienceData from "../../hooks/useExperienceData";
import Loading from "../other/Loading";
import Experiences from "./Experiences";
import Invitation from "./Invitation";

const ExperienceDashboard = () => {
  const {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    confirmationExperiences,
    loading,
    error,
  } = useExperienceData();

  if (loading) {
    return <Loading />;
  }
  return (
    <Container
      sx={{
        "@media (max-width: 600px)": {
          padding: 1,
        },
      }}
    >
      <Box flexDirection={"column"} display={"flex"} gap={3}>
        <Paper
          sx={{
            marginBottom: 2,
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "#f9f9f9",
            "@media (max-width: 600px)": {
              // réduire pour le mobile
              padding: 0,
            },
          }}
        >
          <Typography variant="h6" gutterBottom fontWeight={550}>
            Experiences you are taking part
          </Typography>
          {experienceRegistrations.length !== 0 ? (
            <Experiences
              data={experienceRegistrations}
              type={"invitation confirmed"}
            />
          ) : (
            <Typography>No registrations currently</Typography>
          )}
        </Paper>
        <Paper
          sx={{
            marginBottom: 2,
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "#f9f9f9",
            "@media (max-width: 600px)": {
              // réduire pour le mobile
              padding: 0,
            },
          }}
        >
          <Typography variant="h6" gutterBottom fontWeight={550}>
            Experiences you are invited to
          </Typography>
          {experienceInvitations.length !== 0 ? (
            <Invitation data={experienceInvitations} />
          ) : (
            <Typography>No invitations currently</Typography>
          )}
        </Paper>

        <Paper
          sx={{
            marginBottom: 2,
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "#f9f9f9",
            "@media (max-width: 600px)": {
              // réduire pour le mobile
              padding: 0,
            },
          }}
        >
          <Typography variant="h6" gutterBottom fontWeight={550}>
            Confirmation of compensation by bank transfer
          </Typography>
          {confirmationExperiences.length !== 0 ? (
            <Experiences data={confirmationExperiences} type={"payment"} />
          ) : (
            <Typography>You didn't finished experiences yet</Typography>
          )}
        </Paper>

        <Paper
          sx={{
            marginBottom: 2,
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography fontWeight={550} variant="h6" gutterBottom>
            Experiences you have taken part in
          </Typography>
          <Experiences data={doneExperiences} type={"done"} />
        </Paper>
      </Box>
      <Box marginBottom={5} />
    </Container>
  );
};

export default ExperienceDashboard;
