import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import { useThunk } from "../../hooks/useThunk";
import { getExperienceInvitation } from "../../store/thunks/experience/getExperienceInvitation";
import { getExperienceRegistration } from "../../store/thunks/experience/getExperienceRegistration";
import { extractDateTime } from "../utils/extractDateTime";
import { extracDeadLineTime } from "../utils/extractDeadLineTime";

const actionInvitationParticipant = async (sessionId, actionType) => {
  try {
    const formData = new FormData();
    formData.append("session_id", sessionId);

    const response = await axiosInstance.post(
      `/participant/${actionType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error registering participant:", error);
    return { error: error.response ? error.response.data : "Unknown error" };
  }
};

const InscriptionDialog = ({ open, onClose, experience, onConfirm, type }) => {
  const [loading, setLoading] = useState(false);
  const [runInvitation, isLoadingInvitation, errorInvitation] = useThunk(
    getExperienceInvitation
  );
  const [runRegistration, isLoadingRegistration, errorRegistration] = useThunk(
    getExperienceRegistration
  );

  const handleConfirmClick = async () => {
    if (experience && experience.session_id) {
      setLoading(true);
      const result = await actionInvitationParticipant(
        experience.session_id,
        !type ? "cancel" : "register"
      );

      if (result) {
        await runInvitation();
        await runRegistration();
      }
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {type === "confirm"
          ? "I commit to taking part in this experiment"
          : "Session enrolment cancellation"}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 3 }}>
        {experience && (
          <>
            {!type && (
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Do you really want to cancel your enrolment in this experiment
                session?
              </Typography>
            )}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Experience {experience.experiment_public_name}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Date & Time:
              </Typography>
              <Typography variant="body1">
                {`${extractDateTime(experience.session_name).date} - ${
                  extractDateTime(experience.session_name).time
                }`}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Location:
              </Typography>
              <Typography variant="body1">{experience.lab_name}</Typography>
            </Box>
            {type && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Inscription Deadline:
                </Typography>
                <Typography variant="body1">
                  {extracDeadLineTime(experience.registration_unixtime)}
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", p: 3 }}>
        <Button
          onClick={onClose}
          color="error"
          variant="outlined"
          sx={{ mr: 2, marginLeft: 10 }}
          disabled={loading}
        >
          Return
        </Button>
        <Button
          onClick={handleConfirmClick}
          color="primary"
          variant="contained"
          sx={{ mr: 2, marginRight: 10 }}
          disabled={loading} 
          
        >
          {loading ? (
            <CircularProgress size={24} /> 
            
          ) : (
            "Confirm"
          )}
        </Button>
      </DialogActions>
      {loading && (
        <Backdrop
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1201,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
};

export default InscriptionDialog;
