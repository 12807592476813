import GroupIcon from "@mui/icons-material/Group";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SchoolIcon from "@mui/icons-material/School";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { keyframes, styled } from "@mui/system";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const AnimatedBox = styled(Box)(({ theme, inView }) => ({
  animation: inView ? `${fadeInUp} 1s ease-out forwards` : "none",
  opacity: inView ? 1 : 0,
}));

const AnimatedIcon = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  backgroundColor: theme.palette.secondary.light,
  borderRadius: "50%",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  animation: `${bounce} 2s infinite ease-in-out`,
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  textAlign: "center",
  borderRadius: "15px",
  marginBottom: theme.spacing(4),
  backgroundColor: "#f9f9f9",
}));

const ContactForm = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: "20px",
  textAlign: "center",
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
}));



const LandingPage = () => {
  const [refParticipant, inViewParticipant] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [refBenefits, inViewBenefits] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [refContactForm, inViewContactForm] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleMailto = () => {
    window.location.href =
      "mailto:explab@unil.ch?subject=Inquiry%20about%20Research%20Participation&body=Hello%20LabEx,%0D%0A%0D%0AI%20would%20like%20to%20learn%20more%20about%20participating%20in%20your%20research%20studies.%20Please%20provide%20me%20with%20additional%20information.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A[Your%20Name]";
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "3rem 0" }}>
      <Paper
        elevation={4}
        sx={{
          padding: "4rem",
          borderRadius: "20px",
          marginBottom: "4rem",
          backgroundColor: "secondary.light",
        }}
      >
        <Grid container spacing={4} alignItems="center" ref={refParticipant}>
          <Grid item xs={12} md={6}>
            <AnimatedBox inView={inViewParticipant}>
              <Typography variant="h3" component="h1" gutterBottom>
                Earn Money by Participating in Research
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "1.5rem",
                  fontSize: "1.1rem",
                  lineHeight: 1.8,
                }}
              >
                Are you a student or someone looking to earn extra money? LabEx
                offers you the opportunity to participate in cutting-edge
                research studies. Contribute to meaningful projects, gain
                valuable experience, and get paid for your time and effort. It's
                a flexible way to earn money alongside your studies or job.
              </Typography>
              <Box sx={{ textAlign: "left", marginTop: "2.5rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SchoolIcon />}
                  size="large"
                >
                  Sign Up
                </Button>
              </Box>
            </AnimatedBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReactPlayer
              url={"https://labex.unil.ch/data/orsee_promo.mp4"}
              playing
              muted
              controls
              width="100%"
              height="315px"
              style={{ borderRadius: "15px" }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Section ref={refBenefits}>
        <Grid container spacing={4} padding={5}>
          <Grid item xs={12} md={4}>
            <AnimatedIcon>
              <MonetizationOnIcon fontSize="large" color="primary" />
            </AnimatedIcon>
            <Typography variant="h5" component="h3" gutterBottom>
              Earn Extra Money
            </Typography>
            <Typography variant="body1">
              Get paid for your time by participating in research studies that
              contribute to scientific advancement.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimatedIcon>
              <SchoolIcon fontSize="large" color="primary" />
            </AnimatedIcon>
            <Typography variant="h5" component="h3" gutterBottom>
              Flexible Around Your Schedule
            </Typography>
            <Typography variant="body1">
              Participate in studies at times that work for you, making it easy
              to earn money alongside your studies or job.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimatedIcon>
              <GroupIcon fontSize="large" color="primary" />
            </AnimatedIcon>
            <Typography variant="h5" component="h3" gutterBottom>
              Join a Community
            </Typography>
            <Typography variant="body1">
              Be part of a community of participants and researchers working
              together to advance science.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <ContactForm elevation={4} ref={refContactForm}>
        <AnimatedBox inView={inViewContactForm}>
          <Typography variant="h4" component="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you have any questions or would like to learn more about
            participating in our research studies, please fill out the form
            below and we'll get back to you as soon as possible.
          </Typography>
          <Box sx={{ marginTop: "2rem" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleMailto}
            >
              Send Email to LabEx
            </Button>
          </Box>
        </AnimatedBox>
      </ContactForm>
    </Container>
  );
};

export default LandingPage;
