import React from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";

const PersonalInformationEdu = ({ data }) => {
  return (
    <Container style={{ float: "left", marginRight: "2%" }}>
      <TextField
        label="First Name"
        value={data.fname}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label="Last Name"
        name="lastName"
        value={data.lname}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label="Email Address"
        name="email"
        value={data.email}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label="Date of Birth (dd.mm.yyyy)"
        name="birthDate"
        value={data.date_of_birth}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label="Phone Number (e.g., +41781234567)"
        name="phoneNumber"
        value={data.phone_number}
        fullWidth
        margin="normal"
        disabled={true}
      />
    </Container>
  );
};

export default PersonalInformationEdu;
