import { createSlice } from "@reduxjs/toolkit";
import { getTranslations } from "../thunks/collections/getTranslations";


const userSlice = createSlice({
  name: "collections",
  initialState: {
    lang: "en",
    fields: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTranslations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTranslations.fulfilled, (state, action) => {
        state.fields = [...action.payload];
        state.loading = false;
      });
  }
});

export default userSlice.reducer;
